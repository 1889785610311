// @ts-nocheck
import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';

import LogoCycle from '../_assets/images/logos/logo-cycle-white.svg';
import LogoMirror from '../_assets/images/logos/logo-mirror-white.svg';
import { Color } from '../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../components/common/page-layout/page-layout';
import HeadlineCopytext from '../components/templates/headline-copytext/headline-copytext';
import SectionIntended from '../components/templates/section-intended/section-intended';
import { richText } from '../modules/rich-text';

import SharedStyles from './_scss/shared.module.scss';
import SubscriptionStyles from './_scss/subscription.module.scss';

const SubscriptionPage = () => {
    const intl = useIntl();

    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.subscription.title' })}
            description={intl.formatMessage({ id: 'pages.subscription.description' })}
            pageBackground={PageLayoutBackground.Animated}
        >
            <SectionIntended>
                <div className={SubscriptionStyles.subscriptionGrid}>
                    <div className={SubscriptionStyles.subscriptionSection}>
                        <img src={LogoCycle} height="26" alt="@Cycle" />
                        <HeadlineCopytext
                            color={Color.White}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: richText('pages.subscription.cycle.standard.headlines.0'),
                                },
                                {
                                    style: 'h4',
                                    text: richText('pages.subscription.cycle.standard.headlines.1'),
                                },
                            ]}
                        />

                        <ul>
                            <li>
                                <p>Jede Woche rund 50 Cycling LIVE Classes powered by CYBEROBICS.</p>
                            </li>
                            <li>
                                <p>
                                    Rund 100 weitere LIVE Classes aus den Kategorien Strength, Cardio, Toning, Body &
                                    Mind.
                                </p>
                            </li>
                            <li>
                                <p>Unzählige CYBEROBICS On-Demand-Workouts.</p>
                            </li>
                            <li>
                                <p>Solotraining oder auf dem Leaderboard gegen die Community.</p>
                            </li>
                            <li>
                                <p>Bis zu 6 individuelle Nutzerprofile.</p>
                            </li>
                            <li>
                                <p>Kurse in 3 (live) bzw. 5 (on demand) Sprachen.</p>
                            </li>
                        </ul>

                        <p className="bold color-white">
                            Laufzeit: 12 Monate
                            <sup>
                                <a href="#footnote-(2)">(2)</a>
                            </sup>
                        </p>

                        <p className="color-white">
                            Wir schenken dir den ersten Probemonat deines Horizon@Abos.
                            <sup>
                                <a href="#footnote-(5)">(5)</a>
                            </sup>
                        </p>
                    </div>
                    <div className={SubscriptionStyles.subscriptionSection}>
                        <img src={LogoMirror} height="26" alt="@Mirror" />
                        <HeadlineCopytext
                            color={Color.White}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: richText('pages.subscription.mirror.standard.headlines.0'),
                                },
                                {
                                    style: 'h4',
                                    text: richText('pages.subscription.mirror.standard.headlines.1'),
                                },
                            ]}
                        />

                        <ul>
                            <li>
                                <p>
                                    Jede Woche rund 150 LIVE Classes aus den Kategorien Strength, Cardio, Toning, Body &
                                    Mind und Cycling – powered by CYBEROBICS.
                                </p>
                            </li>
                            <li>
                                <p>Unzählige CYBEROBICS On-Demand-Workouts.</p>
                            </li>
                            <li>
                                <p>
                                    Private 1:1 Workouts mit deinem @Mirror Buddy oder gemeinsam in der Gruppe.
                                    <sup>
                                        <a href="#footnote-(4)">(4)</a>
                                    </sup>
                                </p>
                            </li>
                            <li>
                                <p>Bis zu 6 individuelle Nutzerprofile.</p>
                            </li>
                            <li>
                                <p>Kurse in 3 (live) bzw. 5 (on demand) Sprachen. </p>
                            </li>
                        </ul>

                        <p className="bold color-white">
                            Laufzeit: 12 Monate
                            <sup>
                                <a href="#footnote-(2)">(2)</a>
                            </sup>
                        </p>

                        <p className="color-white">
                            Wir schenken dir den ersten Probemonat deines Horizon@Abos.
                            <sup>
                                <a href="#footnote-(5)">(5)</a>
                            </sup>
                        </p>
                    </div>
                </div>
            </SectionIntended>

            <div className={SubscriptionStyles.subscriptionTeaser}>
                <SectionIntended>
                    <p>
                        <h6>
                            <a
                                href="https://shop-horizonfitness.eu/collections/horizon@"
                                target="shop"
                                rel="noopener noreferrer"
                            >
                                Jetzt unverbindlich testen: 1 Monat Probetraining mit dem Horizon@Cycle oder
                                Horizon@Mirror + Horizon@Abo.
                            </a>

                            <sup style={{ 'font-size': '0.5em' }}>
                                <a href="#footnote-(5)" className="color-white">
                                    (5)
                                </a>
                            </sup>
                        </h6>
                    </p>
                </SectionIntended>
            </div>

            <SectionIntended>
                <div className={SubscriptionStyles.subscriptionGrid}>
                    <div className={SubscriptionStyles.subscriptionSection}>
                        <img src={LogoCycle} height="26" alt="@Cycle" />
                        <HeadlineCopytext
                            color={Color.White}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: richText('pages.subscription.cycle.flex.headlines.0'),
                                },
                                {
                                    style: 'h4',
                                    text: richText('pages.subscription.cycle.flex.headlines.1'),
                                },
                            ]}
                        />

                        <ul>
                            <li>
                                <p>Jede Woche rund 50 Cycling LIVE Classes powered by CYBEROBICS.</p>
                            </li>
                            <li>
                                <p>
                                    Rund 100 weitere LIVE Classes aus den Kategorien Strength, Cardio, Toning, Body &
                                    Mind.
                                </p>
                            </li>
                            <li>
                                <p>Unzählige CYBEROBICS On-Demand-Workouts.</p>
                            </li>
                            <li>
                                <p>Solotraining oder auf dem Leaderboard gegen die Community.</p>
                            </li>
                            <li>
                                <p>Bis zu 6 individuelle Nutzerprofile.</p>
                            </li>
                            <li>
                                <p>Kurse in 3 (live) bzw. 5 (on demand) Sprachen.</p>
                            </li>
                        </ul>

                        <p className="bold color-white">
                            Monatlich kündbar
                            <sup>
                                <a href="#footnote-(3)">(3)</a>
                            </sup>
                        </p>

                        <p className="color-white">
                            Wir schenken dir den ersten Probemonat deines Horizon@Abos.
                            <sup>
                                <a href="#footnote-(5)">(5)</a>
                            </sup>
                        </p>
                    </div>
                    <div className={SubscriptionStyles.subscriptionSection}>
                        <img src={LogoMirror} height="26" alt="@Mirror" />
                        <HeadlineCopytext
                            color={Color.White}
                            alignment="left"
                            headline={[
                                {
                                    style: 'h3',
                                    text: richText('pages.subscription.mirror.flex.headlines.0'),
                                },
                                {
                                    style: 'h4',
                                    text: richText('pages.subscription.mirror.flex.headlines.1'),
                                },
                            ]}
                        />

                        <ul>
                            <li>
                                <p>
                                    Jede Woche rund 150 LIVE Classes aus den Kategorien Strength, Cardio, Toning, Body &
                                    Mind und Cycling – powered by CYBEROBICS.
                                </p>
                            </li>
                            <li>
                                <p>Unzählige CYBEROBICS On-Demand-Workouts.</p>
                            </li>
                            <li>
                                <p>
                                    Private 1:1 Workouts mit deinem @Mirror Buddy oder gemeinsam in der Gruppe.
                                    <sup>
                                        <a href="#footnote-(4)">(4)</a>
                                    </sup>
                                </p>
                            </li>
                            <li>
                                <p>Bis zu 6 individuelle Nutzerprofile.</p>
                            </li>
                            <li>
                                <p>Kurse in 3 (live) bzw. 5 (on demand) Sprachen. </p>
                            </li>
                        </ul>

                        <p className="bold color-white">
                            Monatlich kündbar
                            <sup>
                                <a href="#footnote-(3)">(3)</a>
                            </sup>
                        </p>

                        <p className="color-white">
                            Wir schenken dir den ersten Probemonat deines Horizon@Abos.
                            <sup>
                                <a href="#footnote-(5)">(5)</a>
                            </sup>
                        </p>
                    </div>
                </div>
            </SectionIntended>

            {/*FOOTNOTES START*/}
            <div className="background-color-black">
                <SectionIntended>
                    <div className={SharedStyles.footnotes}>
                        <div className={SharedStyles.footnotesInner}>
                            <table>
                                <tr>
                                    <td>
                                        <p>
                                            <small>(1)</small>
                                        </p>
                                    </td>
                                    <td>
                                        <div id="footnote-(1)">
                                            <p>
                                                <small>Abbuchung erfolgt immer zu Beginn des Abo-Monats.</small>
                                            </p>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <p>
                                            <small>(2)</small>
                                        </p>
                                    </td>
                                    <td>
                                        <div id="footnote-(2)">
                                            <p>
                                                <small>
                                                    Kündigungsfrist: 4 Wochen zum Ende der Laufzeit. Sofern nicht
                                                    fristgerecht gekündigt wurde, verlängert sich der Vertrag
                                                    automatisch um 3 Monate. Ein Wechsel in ein Flex-Abo ist nicht
                                                    möglich.
                                                </small>
                                            </p>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <p>
                                            <small>(3)</small>
                                        </p>
                                    </td>
                                    <td>
                                        <div id="footnote-(3)">
                                            <p>
                                                <small>
                                                    Kündigungsfrist: 7 Tage zum Ende der Laufzeit. Automatische
                                                    Verlängerung um einen weiteren Monat, sofern nicht fristgerecht
                                                    gekündigt wurde. Ein Wechsel in ein Vorteilsabo ist immer bis 7 Tage
                                                    vor Ablauf der Laufzeit möglich.
                                                </small>
                                            </p>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <p>
                                            <small>(4)</small>
                                        </p>
                                    </td>
                                    <td>
                                        <div id="footnote-(4)">
                                            <p>
                                                <small>
                                                    Die Nutzung des 1:1 Trainings ist für einen Familienaccount auf 6
                                                    Stunden pro Woche limitiert. Nach Ablauf des freien Kontingents wird
                                                    das 1:1 Training automatisch beendet. Bei Nutzung des 1:1 Trainings
                                                    kann ausschließlich über ein separat erhältliches Bluetooth Headset
                                                    miteinander kommuniziert werden.
                                                </small>
                                            </p>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <p>
                                            <small>(5)</small>
                                        </p>
                                    </td>
                                    <td>
                                        <div id="footnote-(5)">
                                            <p>
                                                <small>
                                                    Der Kauf eines @Cycles oder eines @Mirrors ist mit einer
                                                    unverbindlichen 30-tägigen Testphase verbunden. Du hast Anspruch auf
                                                    eine 30-tägige Testphase pro Haushalt/Lieferadresse. Das Angebot ist
                                                    nicht übertragbar und gilt nur für Kunden in Deutschland und
                                                    Österreich innerhalb des Liefergebiets der Johnson Health Tech.
                                                    GmbH. Das Angebot über eine 30-tägige Testphase gilt nur für
                                                    Verbraucher, die online auf www.shop-horizonfitness.eu ein @Cycle
                                                    oder einen @Mirror erworben haben und ist nicht für Unternehmen
                                                    verfügbar. Das 30-tägige Probetraining beginnt mit der Aktivierung
                                                    des @Mirrors bzw. des @Cycles mit dem bereitgestellten
                                                    Aktivierungscode.
                                                </small>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </SectionIntended>
            </div>
            {/*FOOTNOTES END*/}
        </PageLayout>
    );
};

export default SubscriptionPage;
